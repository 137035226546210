import { Grid, Select, Text, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';

import { GoogleAutocomplete, MaskedInput } from 'components';
import { useDeliveryData, useAutocompleteAddress } from 'hooks';
import { useEffect } from 'react';
import {
  CITY_INPUT,
  COUNTRY_INPUT,
  DATE_FORMAT_SLASH,
  DATE_OF_BIRTH_INPUT,
  DEPLETE_FROM_INVENTORY_LOCATION_DROPDOWN,
  DEPLETE_FROM_PRIMARY_LOCATION_DROPDOWN,
  EMAIL_INPUT,
  FIRST_NAME_INPUT,
  FREIGHT_COMPANY,
  FREIGHT_COMPANY_DROPDOWN,
  FREIGHT_METHOD,
  FREIGHT_METHOD_DROPDOWN,
  LAST_NAME_INPUT,
  PHONE_NUMBER_INPUT,
  SHIPPING_DATE_INPUT,
  STATE_INPUT,
  stateOptions,
  stateOptionsObj,
  STREET_ADDRESS_2_INPUT,
  STREET_ADDRESS_INPUT,
  SubOrderInitialValues,
  SUPPLY_TYPE_DROPDOWN,
  SupplyType,
  supplyTypeOptions,
  TRACKING_NUMBER_INPUT,
  ZIP_CODE_INPUT,
} from 'shared';

type Props = {
  form: UseFormReturnType<SubOrderInitialValues>;
};

export const DeliveryInformation = ({ form }: Props) => {
  const { values, setFieldValue } = form;
  const { supply_type, deplete_location_id } = values;

  const { inventoryLocationOptions, primaryLocationOptions } = useDeliveryData({
    supplyType: supply_type,
    primaryLocationId: deplete_location_id ?? undefined,
  });

  const { setCurrentData, fillData } = useAutocompleteAddress({});

  useEffect(() => {
    if (fillData) {
      setFieldValue('shipping_address', {
        ...values.shipping_address,
        ...fillData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillData]);

  return (
    <>
      <Grid.Col span={12}>
        <Text size="lg" weight={600}>
          Delivery Information
        </Text>
      </Grid.Col>

      <Grid.Col span={4}>
        <TextInput
          required
          label="Recipient's Name"
          data-testid={FIRST_NAME_INPUT}
          {...form.getInputProps('shipping_address.first_name')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          required
          label="Recipient's Last Name"
          data-testid={LAST_NAME_INPUT}
          {...form.getInputProps('shipping_address.last_name')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          label="Business"
          {...form.getInputProps('shipping_address.business')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <MaskedInput
          required
          placeholder="(111) 111-1111"
          label="Phone Number"
          mask="(999) 999-9999"
          data-testid={PHONE_NUMBER_INPUT}
          {...form.getInputProps('shipping_address.phone_number')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          required
          placeholder="your@email.com"
          label="Email Address"
          data-testid={EMAIL_INPUT}
          {...form.getInputProps('shipping_address.email')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <MaskedInput
          required
          testId="dateOfBirth"
          mask="99.99.9999"
          label="Date of Birth"
          placeholder="08.31.1989"
          maskChar={null}
          data-testid={DATE_OF_BIRTH_INPUT}
          {...form.getInputProps('shipping_address.date_of_birth')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <GoogleAutocomplete setValues={setCurrentData}>
          <TextInput
            required
            label="Street Address"
            date-testid={STREET_ADDRESS_INPUT}
            {...form.getInputProps('shipping_address.address_line_1')}
          />
        </GoogleAutocomplete>
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          label="Street Address 2"
          date-testid={STREET_ADDRESS_2_INPUT}
          {...form.getInputProps('shipping_address.address_line_2')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          required
          label="City"
          date-testid={CITY_INPUT}
          {...form.getInputProps('shipping_address.city')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          searchable
          label="State"
          date-testid={STATE_INPUT}
          data={
            form.values.shipping_address?.state?.length === 2
              ? stateOptionsObj
              : stateOptions
          }
          {...form.getInputProps('shipping_address.state')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          required
          label="Zip Code"
          date-testid={ZIP_CODE_INPUT}
          {...form.getInputProps('shipping_address.zip_code')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          required
          label="Country"
          date-testid={COUNTRY_INPUT}
          {...form.getInputProps('shipping_address.country')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Supply Type"
          date-testid={SUPPLY_TYPE_DROPDOWN}
          data={supplyTypeOptions()}
          {...form.getInputProps('supply_type')}
          onChange={(value) => {
            if (!value) return;
            setFieldValue('supply_type', value as SupplyType);
            setFieldValue('deplete_location_id', '');
            setFieldValue('deplete_inventory_location_id', '');
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          date-testid={DEPLETE_FROM_PRIMARY_LOCATION_DROPDOWN}
          label="Deplete from Primary Location"
          data={[...primaryLocationOptions]}
          {...form.getInputProps('deplete_location_id')}
          onChange={(value) => {
            if (!value) return;
            setFieldValue('deplete_location_id', value);
            setFieldValue('deplete_inventory_location_id', value);
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          data-testid={DEPLETE_FROM_INVENTORY_LOCATION_DROPDOWN}
          label="Deplete from Inventory Location"
          data={inventoryLocationOptions}
          {...form.getInputProps('deplete_inventory_location_id')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Company"
          data={FREIGHT_COMPANY}
          data-testid={FREIGHT_COMPANY_DROPDOWN}
          {...form.getInputProps('freight_company')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Freight Method"
          data={FREIGHT_METHOD}
          date-testid={FREIGHT_METHOD_DROPDOWN}
          {...form.getInputProps('freight_method')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DatePickerInput
          required
          label="Shipping Date"
          placeholder="mm/dd/yyyy"
          date-testid={SHIPPING_DATE_INPUT}
          valueFormat={DATE_FORMAT_SLASH}
          {...form.getInputProps('shipping_date')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          label="Tracking #"
          date-testid={TRACKING_NUMBER_INPUT}
          {...form.getInputProps('tracking_number')}
        />
      </Grid.Col>
    </>
  );
};
