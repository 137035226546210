export const BTN = 'btn';
export const DROPDOWN = 'dropdown';

export const LOADER = 'loader';
export const LOADING_OVERLAY = 'loading-overlay';

export const SAVE_BTN = 'save-btn';
export const BACK_BTN = 'back-btn';
export const RECEIVE_BTN = 'receive-btn';
export const CANCEL_BTN = 'cancel-btn';
export const CONFIRM_BTN = 'confirm-btn';
export const DELETE_BTN = 'delete-btn';
export const UPLOAD_BTN = 'upload-btn';
export const DOWNLOAD_BTN = 'download-btn';
export const SEE_CHANGE_HISTORY_BTN = 'see-change-history-btn';
export const ADD_PRODUCT_BTN = 'add-product-btn';

export const SEARCH_INPUT = 'search-input';
export const PRODUCT_INPUT = 'product-input';
export const EMAIL_INPUT = 'email-input';
export const FIRST_NAME_INPUT = 'first-name-input';
export const LAST_NAME_INPUT = 'last-name-input';
export const BUSINESS_INPUT = 'business-input';
export const PHONE_NUMBER_INPUT = 'phone-number-input';
export const DATE_OF_BIRTH_INPUT = 'date-of-birth-input';
export const STREET_ADDRESS_INPUT = 'street-address-input';
export const STREET_ADDRESS_2_INPUT = 'street-address-2-input';
export const CITY_INPUT = 'city-input';
export const STATE_INPUT = 'state-input';
export const ZIP_CODE_INPUT = 'zip-code-input';
export const COUNTRY_INPUT = 'country-input';
export const SHIPPING_DATE_INPUT = 'shipping-date-input';
export const TRACKING_NUMBER_INPUT = 'tracking-number-input';

export const DEPLETE_FROM_PRIMARY_LOCATION_DROPDOWN =
  'deplete-from-primary-location-dropdown';

export const DEPLETE_FROM_INVENTORY_LOCATION_DROPDOWN =
  'deplete-from-inventory-location-dropdown';

export const PROMOCODE_DROPDOWN = 'promocode-dropdown';
export const PAYMENT_METHOD_DROPDOWN = 'payment-method-dropdown';
export const FREIGHT_COMPANY_DROPDOWN = 'freight-company-dropdown';
export const FREIGHT_METHOD_DROPDOWN = 'freight-method-dropdown';
export const SUPPLY_TYPE_DROPDOWN = 'supply-type-dropdown';
export const UPDATED_BY_DROPDOWN = 'updated-by-dropdown';
export const CHANNEL_DROPDOWN = 'channel-dropdown';
export const LOCATION_DROPDOWN = 'primary-location-dropdown';
export const INVENTORY_LOCATION_DROPDOWN = 'inventory-location-dropdown';
export const SECONDARY_LOCATION_DROPDOWN = 'secondary-location-dropdown';
export const SHIPMENT_CYCLE_DROPDOWN = 'shipment-cycle-dropdown';
export const PICKUP_LOCATION_DROPDOWN = 'pickup-location-dropdown';

export const SHIPMENT_SWITCH = 'shipment-switch';
export const PICKUP_SWITCH = 'pickup-switch';
