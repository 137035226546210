import { Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SelectAsync } from 'components/selectAsync';
import { useDiscounts } from 'hooks';
import { CycleOrderPayload, PROMOCODE_DROPDOWN, SelectOption } from 'shared';

type Props = {
  form: UseFormReturnType<
    CycleOrderPayload,
    (values: CycleOrderPayload) => CycleOrderPayload
  >;
};

export const PromocodeSelect = ({ form }: Props) => {
  const {
    hasMore,
    discountOptions,
    isLoading,
    setPageSelectAsync,
    handleSetSearchString,
  } = useDiscounts({ isSelectAsync: true });

  return (
    <Grid gutter="xl" columns={24}>
      <Grid.Col span={7} mb={20}>
        <SelectAsync
          isClearable
          isSearchable
          searchByString={({ search_string }) =>
            handleSetSearchString(search_string)
          }
          label="Promocode"
          data-testid={PROMOCODE_DROPDOWN}
          setPage={setPageSelectAsync}
          hasMore={hasMore}
          isLoading={isLoading}
          defaultValue={form.values.defaultDiscount}
          options={discountOptions}
          {...form.getInputProps('promocode_id')}
          onChange={(item) => {
            const { value } = (item as SelectOption) ?? {};

            if (!item) return form.setFieldValue('promocode_id', null);

            form.setFieldValue('promocode_id', value);
          }}
        />
      </Grid.Col>
    </Grid>
  );
};
