import { Grid, Select, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { GoogleAutocomplete } from 'components';
import { Address, stateOptions, stateOptionsObj } from 'shared';
import { useAutocompleteAddress } from 'hooks';

type Props<T> = {
  form: UseFormReturnType<T>;
};

export const AddressFields = <T extends Address>({
  form,
}: Props<T>): JSX.Element => {
  const { setCurrentData } = useAutocompleteAddress<T>({
    setValues: form.setValues,
  });

  const fullWidth = { width: '100%' };

  return (
    <>
      <Grid.Col>
        <TextInput
          sx={fullWidth}
          data-testid="business-input"
          label="Business"
          {...form.getInputProps('business')}
        />
      </Grid.Col>

      <Grid.Col>
        <GoogleAutocomplete setValues={setCurrentData}>
          <TextInput
            required
            {...form.getInputProps('street_address_one')}
            value={form.values.street_address_one ?? ''}
            data-testid="primary-street-address-input"
            label="Street Address"
          />
        </GoogleAutocomplete>
      </Grid.Col>

      <Grid.Col>
        <TextInput
          data-testid="secondary-street-address-input"
          label="Street Address 2"
          {...form.getInputProps('street_address_two')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          required
          sx={fullWidth}
          data-testid="city-input"
          label="City"
          {...form.getInputProps('city')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          searchable
          required
          sx={fullWidth}
          data-testid="state-input"
          label="State"
          data={
            form.values.state?.length === 2 ? stateOptionsObj : stateOptions
          }
          {...form.getInputProps('state')}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <TextInput
          required
          sx={fullWidth}
          data-testid="zip-input"
          label="Zip"
          {...form.getInputProps('zip_code')}
        />
      </Grid.Col>
      <Grid.Col>
        <TextInput
          required
          sx={fullWidth}
          data-testid="country-input"
          label="Country"
          {...form.getInputProps('country')}
        />
      </Grid.Col>
    </>
  );
};
