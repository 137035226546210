import { ProductCategoryFormImages } from './category';
import { PaginatedRequest } from '../common';
import {
  InventorySkuItem,
  InventoryType,
  TaxRate,
} from '../inventory/inventory';

export interface ProfileSku {
  price: string | null;
  saved_as_draft: boolean;
  product_name: string;
  is_enabled: boolean;
  hide_from_pos: boolean;
  show_in_ecommerce: boolean;
  inventory_type?: InventoryType;
  category_id: string | null;
  subcategory_id: string | null;
  cost?: number;
  tax_rate: TaxRate;
  vendor_id: string | null;
}

export interface AdditionalInformationSku {
  product_file: string | null;
  e_gift_card: boolean;
  product_description: string | null;
  comment: string | null;
}

export type CategoryOption = {
  id: string;
  value: string;
  name?: string;
  required?: boolean;
};

export interface ProductDto
  extends Omit<ProfileSku, 'price'>,
    AdditionalInformationSku {
  id: string;
  sku_number?: string | null;
  category?: {
    id: string;
    name: string;
  };
  subcategory?: {
    id: string;
    name: string;
  };
  price?: string;
  margin: number;
  vendor?: {
    id: string;
    company_name: string;
  };
  processing_time: string;
  pickup_only: boolean;
  members_only: boolean;
  ships_separately: boolean;
  width?: number;
  height?: number;
  depth?: number;
  weight?: number;
  per_order_limit: string | null;
  sku_type?: string;
  specific_information: {
    items?:
      | {
          id: string;
          quantity: number;
          sku_number?: string;
          product_name?: string;
        }[]
      | null;
    is_available_by_glass?: boolean;
    is_available_only_by_glass?: boolean;
    has_sample?: boolean;
    abv?: string;
    bottle_size?: string | null;
    varietal?: string | null;
    glasses_per_bottle?: string | null;
    locations?: {
      key?: string;
      location: {
        id: string;
        name?: string;
        type?: string;
      };
      inventory_location: {
        id: string;
        name?: string;
        show_in_pos?: boolean;
        pool?: string;
      };
    }[];
  } | null;
  btg?: {
    btg_tax_rate?: TaxRate;
    btg_cost?: number;
    btg_price?: string;
    btg_sku_number?: string;
    btg_margin?: number;
  } | null;
  mobile_image_url?: string | null;
  web_image_url?: string | null;
  web_hover_image_url?: string | null;
  category_options?: CategoryOption[] | null;
}

export interface CreateSku
  extends Omit<
    ProductDto,
    'id' | 'category' | 'subcategory' | 'margin' | 'price' | 'vendor'
  > {
  category_id: string | null;
  subcategory_id: string | null;
  vendor_id: string | null;
  price: string | null;
  is_kit?: boolean;
  is_non_inventory?: boolean;
}

export type ShortSkuDto = Omit<
  InventorySkuItem,
  'quantity_lib' | 'price' | 'cost' | 'quantity'
>;

export enum SkuStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Draft = 'draft',
}

export interface SkusRequest extends PaginatedRequest {
  search_string?: string;
  has_inventory?: boolean;
  is_culinary?: boolean;
  is_alcohol?: boolean;
  inventory_type?: string;
  category?: string;
  status?: SkuStatus | '';
}

export type SkuDto = {
  id: string;
  sku_number: string;
  product_name: string;
  comment?: string;
  inventory_type: InventoryType;
  price: number;
};

export type SkuFormImages = ProductCategoryFormImages & {
  webHoverImage: File | null;
};
