import { useCallback, ReactNode, useEffect } from 'react';
import { Container, Grid, Select, Text, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  ClubMembershipValues,
  getEmptyOption,
  AccountGroup,
  accountGroupLabels,
  Permissions,
  SelectOption,
} from 'shared';
import { ClubMemberTierFields, ClubMemberDeliveryFields } from './components';
import { useClubTier, usePermissions } from 'hooks';
import { NONE_VALUE } from '../../constants';

export type Props = {
  form: UseFormReturnType<ClubMembershipValues>;
  isEdit?: boolean;
  isUnpaid?: boolean;
  isShipment?: boolean;
  updateOrders?: boolean;
  setUpdateOrders?: React.Dispatch<React.SetStateAction<boolean>>;
  statusData?: ReactNode;
  tastingLimitSection?: ReactNode;
  skipHistory?: ReactNode;
  actions?: ReactNode;
  accountGroup?: AccountGroup;
  addressOptions?: SelectOption[];
};

export const ClubMemberForm = ({
  form,
  isEdit = false,
  isUnpaid = false,
  isShipment = false,
  updateOrders = false,
  setUpdateOrders = () => null,
  statusData,
  skipHistory,
  tastingLimitSection,
  actions = null,
  accountGroup,
  addressOptions,
}: Props): JSX.Element => {
  const { values, setFieldValue } = form;
  const isNoneTier = values.tier.id === NONE_VALUE;
  const {
    getTierById,
    options: tierOptions,
    isLoading,
  } = useClubTier({
    level: 'main',
  });

  const { options: addOnOptions, getTierById: getAddonById } = useClubTier({
    tierId: values.tier.id,
    enabled: !!values.tier.id && !isNoneTier,
  });

  const { checkAccess } = usePermissions();

  const canEdit = checkAccess(Permissions.admin_panel_customer_edit);

  const options = [getEmptyOption('None', NONE_VALUE), ...tierOptions];

  const handleTierSelect = useCallback(
    (id: string) => {
      if (id === values.tier.id) return;
      if (id === NONE_VALUE) {
        setFieldValue('tier.id', id);
        setFieldValue('add_on.id', '');
        return;
      }
      const selectedTier = getTierById(id);
      if (!selectedTier) {
        return;
      }
      setFieldValue('tier.id', id);
      setFieldValue('add_on.id', '');
      setFieldValue(
        'tier.wine_type',
        selectedTier.wine_types_allowed[0] || null,
      );
      setFieldValue(
        'tier.bottle_quantity',
        selectedTier.bottle_quantity_allowed[0] || null,
      );
    },
    [getTierById, setFieldValue, values.tier.id],
  );

  const handleAddonSelect = useCallback(
    (id: string) => {
      const selectedAddon = getAddonById(id);
      if (!selectedAddon) {
        setFieldValue('add_on.id', '');
        return;
      }
      setFieldValue('add_on.id', id);
      setFieldValue(
        'add_on.wine_type',
        selectedAddon.wine_types_allowed[0] || null,
      );
      setFieldValue(
        'add_on.bottle_quantity',
        selectedAddon.bottle_quantity_allowed[0] || null,
      );
    },
    [getAddonById, setFieldValue],
  );

  const selectedTier = getTierById(values.tier.id ?? '');
  const selectedAddon = getAddonById(values.add_on?.id ?? '');

  const hasShipment =
    !!selectedTier &&
    selectedTier?.name.toLowerCase() !== AccountGroup.EMPLOYEE;

  useEffect(() => {
    setFieldValue('hasShipment', hasShipment);
  }, [hasShipment, setFieldValue]);

  return (
    <Container size="xl">
      <form>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <Grid gutter={'xl'}>
              <Grid.Col>
                <Text size={18} weight={700}>
                  Club Membership Tier
                </Text>
              </Grid.Col>
              {isEdit && (
                <Grid.Col>
                  <Text size="sm" weight={600}>
                    Account Group
                  </Text>
                  <Text mt="xs">
                    {accountGroupLabels[accountGroup as AccountGroup]}
                  </Text>
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col span={6}>{statusData ?? null}</Grid.Col>

          <Grid.Col span={6}>
            <Select
              required
              disabled={isLoading}
              data-testid="club-membership-tier"
              label="Club Membership Tier"
              data={options}
              {...form.getInputProps('tier.id')}
              onChange={(value) => handleTierSelect(value ?? '')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              disabled={isNoneTier}
              required
              data-testid="club-membership-addon"
              label="Club Membership Add-On"
              data={[getEmptyOption('None'), ...addOnOptions]}
              {...form.getInputProps('add_on.id')}
              onChange={(value) => handleAddonSelect(value ?? '')}
            />
          </Grid.Col>

          {tastingLimitSection && <Grid.Col>{tastingLimitSection}</Grid.Col>}

          {isEdit && canEdit && (
            <Grid.Col mt="sm">
              <Switch
                checked={values.is_cycle_on_hold}
                label="Exclude from Shipment Cycle"
                {...form.getInputProps('is_cycle_on_hold')}
              />
            </Grid.Col>
          )}

          {!!selectedTier && (
            <Grid.Col>
              <ClubMemberTierFields
                form={form}
                selectedAddon={selectedAddon}
                selectedTier={selectedTier}
              />
            </Grid.Col>
          )}
          <Grid.Col mt="sm">{skipHistory}</Grid.Col>
          {hasShipment && (
            <Grid.Col>
              <ClubMemberDeliveryFields
                form={form}
                isShipment={isShipment}
                isEdit={isEdit}
                isUnpaid={isUnpaid}
                updateOrders={updateOrders}
                setUpdateOrders={setUpdateOrders}
                addressOptions={addressOptions}
              />
            </Grid.Col>
          )}
        </Grid>
      </form>
      {actions && (
        <Container size={'xs'} px={0} mt="xl">
          {actions}
        </Container>
      )}
    </Container>
  );
};
