import {
  Button,
  Flex,
  Grid,
  NumberInput,
  SegmentedControl,
  SelectItem,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';
import { reject, without } from 'rambda';
import { CreateDiscount, DiscountUnit, SWITCH_GRID_TOP_OFFSET } from 'shared';
import { HALTER_RANCH_LOCATION_ID } from 'App/contexts';
import { PrimaryLocation } from './components';
import { getNewItem } from '../helpers';

type Props = {
  form: UseFormReturnType<CreateDiscount>;
  options: SelectItem[];
  initialLocationIds: string[];
  isEdit?: boolean;
};

export const ProductProfile = ({
  form,
  options,
  initialLocationIds,
  isEdit,
}: Props) => {
  const { values } = form;
  const { location_ids, unit, value, is_used } = values;

  const disabledAddLocation = location_ids.some(
    (item) => item === HALTER_RANCH_LOCATION_ID || item === '',
  );
  const isPrimaryLocationHalterRanch =
    location_ids[0] === HALTER_RANCH_LOCATION_ID;

  const isPrecision = unit === DiscountUnit.dollar;
  const isMax = unit === DiscountUnit.percentage;

  const valueFieldProps = {
    ...form.getInputProps('value'),
    ...(isMax ? { max: 100 } : {}),
    ...(isPrecision ? { precision: 2 } : {}),
  };

  return (
    <Grid gutter="xl" mb="xl">
      <Grid.Col>
        <Text color="dark" weight={600} size="lg">
          Product Profile
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          required
          disabled={is_used}
          label="Promocode Name"
          {...form.getInputProps('name')}
        />
      </Grid.Col>
      <Grid.Col span={2} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Enable"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('is_active', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col span={4} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Hide from E-Commerce"
          labelPosition="right"
          disabled={!isPrimaryLocationHalterRanch || is_used}
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('hide_from_ecommerce', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Flex
          gap="lg"
          align="start"
          sx={{ width: '50%', paddingRight: '12px' }}
        >
          <NumberInput
            sx={{ width: '100%' }}
            required
            min={0}
            hideControls
            label="Value"
            disabled={is_used}
            {...valueFieldProps}
            value={value ?? ''}
          />
          <SegmentedControl
            mt="lg"
            sx={{ overflow: 'inherit', backgroundColor: 'gray.1' }}
            radius="sm"
            disabled={is_used}
            data={[
              { value: '%', label: '%' },
              { value: '$', label: '$' },
            ]}
            {...form.getInputProps('unit')}
          />
        </Flex>
      </Grid.Col>
      {location_ids.map((item, index) => {
        const checkFirstLocation = location_ids.some(
          (i) => i && i !== HALTER_RANCH_LOCATION_ID,
        );
        const availableOptions = options.map((i) =>
          checkFirstLocation && i.value === HALTER_RANCH_LOCATION_ID
            ? { ...i, disabled: true }
            : i,
        );

        const rejectItems = without([item], location_ids);

        const available = reject(
          ({ value }) => rejectItems.includes(value),
          availableOptions,
        );

        const isDefault = initialLocationIds.includes(item);
        const disabledDeleteLocation = !!isEdit && is_used && isDefault;

        return (
          <PrimaryLocation
            key={item}
            form={form}
            data={available}
            index={index}
            disabledDeleteLocation={disabledDeleteLocation}
          />
        );
      })}

      <Button
        variant="white"
        ml={12}
        disabled={!!disabledAddLocation}
        onClick={() => form.insertListItem('location_ids', getNewItem())}
      >
        <IconPlus />
        <Text weight={600} size="sm" ml={10}>
          Add Location
        </Text>
      </Button>
    </Grid>
  );
};
