import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  colors: {
    dark: [
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5C5F66',
      '#373A40',
      '#2C2E33',
      '#25262B',
      '#1A1B1E',
      '#141517',
      '#101113',
    ],
    gray: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343A40',
      '#212529',
    ],
    red: [
      '#FFF5F5',
      '#FFE3E3',
      '#FFC9C9',
      '#FFA8A8',
      '#FF8787',
      '#FF6B6B',
      '#FA5252',
      '#F03E3E',
      '#E03131',
      '#C92A2A',
    ],
    green: [
      '#EBFBEE',
      '#D3F9D8',
      '#B2F2BB',
      '#8CE99A',
      '#69DB7C',
      '#51CF66',
      '#40C057',
      '#37B24D',
      '#2F9E44',
      '#2B8A3E',
    ],
    yellow: [
      '#FFF9DB',
      '#FFF3BF',
      '#FFEC99',
      '#FFE066',
      '#FFD43B',
      '#FCC419',
      '#FAB005',
      '#F59F00',
      '#F08C00',
      '#E67700',
    ],
    halterBlue: [
      '#DFF2FA',
      '#B4E1F2',
      '#8ED3EF',
      '#66C0E6',
      '#3EA8D5',
      '#1B99CD',
      '#0A8ABF',
      '#037EB1',
      '#0174A4',
      '#006994',
    ],
  },
  primaryColor: 'halterBlue',
  white: '#fff',
  black: '#000',
  components: {
    AppShell: {
      styles: (theme) => ({
        main: {
          backgroundColor: theme.colors.gray[0],
        },
      }),
    },
    NavLink: {
      defaultProps: {
        color: 'halterBlue',
        variant: 'subtle',
      },
    },
    Stepper: {
      styles: {
        steps: {
          maxWidth: 1050,
          margin: '0 auto',
        },
        separator: { height: 4 },
        separatorActive: { height: 4 },
      },
    },
    Switch: {
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    },
    InputWrapper: {
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    },
    Tabs: {
      styles: {
        tabsList: {
          borderBottom: 0,
        },
      },
    },
  },
  spacing: {
    xs: '8px',
  },
};
